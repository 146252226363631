import { provideAuth, connectAuthEmulator, getAuth, AuthErrorCodes } from '@angular/fire/auth'

import { AUTH } from './emulator-ports'

// import 'firebase/auth'

export function getAuthModule(useEmulators = false) {
  return provideAuth(() => {
    const auth = getAuth()
    if (useEmulators) {
      connectAuthEmulator(auth, `http://localhost:${AUTH}`, {
        disableWarnings: true,
      })
    }
    return auth
  })
}

/**
 *
 * @param code エラーコード
 * https://github.com/firebase/firebase-js-sdk/blob/main/docs-devsite/auth.md#autherrorcodes
 */
export function getAuthErrorMessage(code: string) {
  switch (code) {
    case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
      return '短時間内でのリクエスト回数が多い為、一時的に制限されています。'
    case AuthErrorCodes.INVALID_CODE:
      return '確認コードが間違っています'
    case AuthErrorCodes.INVALID_PASSWORD:
    case AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
      return 'メールアドレスもしくはパスワードが正しくありません'
    case AuthErrorCodes.EMAIL_EXISTS:
      return '既に登録済みのメールアドレスです'
    case AuthErrorCodes.INVALID_PHONE_NUMBER:
      return '電話番号が正しくありません'
    default:
      console.error(`unknown authentication error: ${code}`)
      return '何らかの理由によりエラーが発生しました'
  }
}
