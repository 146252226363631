import { provideFirebaseApp, initializeApp as initApp, FirebaseOptions } from '@angular/fire/app'
// import { initializeApp } from 'firebase/app'
// import { environment } from '~env'

// initializeApp(environment.firebaseConfig)

export function getAppModule(firebase: FirebaseOptions) {
  return provideFirebaseApp(() => initApp(firebase))
}

export * from './auth'
export * from './firestore'
export * from './functions'
export * from './remote-config'
export * from './storage'
