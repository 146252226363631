import { Channel } from '@hamposaki/livmap-ts-utils/types/db/client/channels'
import { Client } from '@hamposaki/livmap-ts-utils/types/db/clients'

const LIVE = (clientId: Client['id'], channelId: Channel['id']) => [clientId, channelId]
export const NAVIGATES = {
  TOP: '',
  // SIGN_IN: '/auth/sign-in',
  SIGN_IN: ['auth', 'sign-in'],
  // CLIENTS: '/clients',
  CLIENTS: ['clients'],
  // USER_CREATE: '/user/create',
  USER_CREATE: ['user', 'create'],
  // EMAIL_CONFIRMATION: '/settings/email/confirmation',
  EMAIL_CONFIRMATION: ['settings', 'email', 'confirmation'],
  // NO_CLIENTS: ['no-clients'],
  NO_CLIENTS: [''],
  LIVE,
  SIGNED_IN: LIVE,
  DATA_LIST: (clientId: Client['id'], channelId: Channel['id']) => [clientId, channelId, 'data-list'],
  ADMIN: ['admin'],
  // NO_CLIENTS: '/no-clients',
  // CLIENT: (clientId: string) => `/${NAVIGATES.CLIENTS.join('/')}/${clientId}`,
  ERRORS: {
    404: ['errors', '404'],
  },
}

export const PARSE_URLS = {
  SIGN_IN: `/${NAVIGATES.SIGN_IN.join('/')}`,
  CLIENTS: `/${NAVIGATES.CLIENTS.join('/')}`,
  USER_CREATE: `/${NAVIGATES.USER_CREATE.join('/')}`,
  EMAIL_CONFIRMATION: `/${NAVIGATES.EMAIL_CONFIRMATION.join('/')}`,
  NO_CLIENTS: `/${NAVIGATES.NO_CLIENTS.join('/')}`,
  SIGNED_IN: (clientId: Client['id'], channelId: Channel['id']) =>
    `/${NAVIGATES.SIGNED_IN(clientId, channelId).join('/')}`,
  LIVE: (clientId: Client['id'], channelId: Channel['id']) => `/${NAVIGATES.LIVE(clientId, channelId).join('/')}`,
  DATA_LIST: (clientId: Client['id'], channelId: Channel['id']) =>
    `/${NAVIGATES.DATA_LIST(clientId, channelId).join('/')}`,
  ADMIN: `${NAVIGATES.ADMIN.join('/')}`,
  ERRORS: {
    404: `${NAVIGATES.ERRORS[404].join('/')}`,
  },
}
